import React, { FC } from 'react';
import backgroundImageImg from '/public/images/downloadAppMask.png';

import {
  Container,
  DownloadBannerWrapper,
  InfoBlock,
  BannerImageContainer,
  BannerImage,
  BannerTitle,
  BannerDescription,
  PromoImageContainer,
  PromoImage,
} from './style';
import { Flex } from '../Layout/Flex';
import Image from 'next/image';
import { IMedia } from '../../../util/types';
import { DownloadImgLinWrapper } from '../../DownloadContent/style';

export type DownloadAppType = {
  link?: string;
  image: IMedia;
};

type PropsType = {
  data: {
    title?: string;
    description?: string;
    showcaseImage?: IMedia;
    applinks?: DownloadAppType[];
  };
};

export const DownloadAppBanner: FC<PropsType> = ({ data = {} }) => {
  if (!data?.showcaseImage?.data) {
    return (
      <DownloadBannerWrapper>
        <Container />
      </DownloadBannerWrapper>
    );
  }
  const { title, showcaseImage, description, applinks } = data;

  // const backgroundImageUrl = backgroundImage.data.attributes.url;
  const imageUrl = showcaseImage.data?.attributes.url;

  const links = applinks?.map((item, index) => (
    <DownloadImgLinWrapper key={`choose-platform-${index}`} isAppStore={true}>
      <a href={item?.link} target={'_blank'} rel="noreferrer">
        <Image
          src={item.image?.data.attributes.url}
          width="162px"
          height="48px"
          alt={`download-app-${index}`}
        />
      </a>
    </DownloadImgLinWrapper>
  ));
  return (
    <DownloadBannerWrapper>
      <Container>
        <BannerImageContainer>
          <BannerImage src={backgroundImageImg} width="100%" height="100%" layout="fill" />
        </BannerImageContainer>
        <InfoBlock>
          <BannerTitle>{title}</BannerTitle>
          <BannerDescription>{description}</BannerDescription>
          <Flex flexDirection="row" margin={'0 auto'} gap="20px" width="initial">
            {links}
          </Flex>
        </InfoBlock>
      </Container>
      <PromoImageContainer>
        <PromoImage
          src={imageUrl}
          width="431px"
          height={
            (431 * showcaseImage.data.attributes.height) / showcaseImage.data.attributes.width
          }
          layout="responsive"
          objectFit="cover"
        />
      </PromoImageContainer>
    </DownloadBannerWrapper>
  );
};
