import colors from '@pickles/shared/utils/colors';
import Image from 'next/image';
import styled from 'styled-components';
import sizes from '../../../util/sizes';

interface IDownloadBannerWrapper {
  imageHeight?: number;
}

// TODO: fix width: change calc
export const DownloadBannerWrapper = styled.div<IDownloadBannerWrapper>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  width: 100%;
  height: ${({ imageHeight = 397 }) => imageHeight + 'px'};
  margin: 0 auto;
  margin-bottom: 112px;
  border-radius: 24px;
  max-width: ${sizes.XL};

  @media (max-width: ${sizes.M}) {
    overflow: hidden;
    margin-bottom: 0;
    height: 471px;
    border-radius: 0;
  }

  @media (max-width: ${sizes.S}) {
    margin-top: 40px;
  }
`;

export const Container = styled.div`
  position: relative;
  height: 280px;
  width: 100%;
  background: linear-gradient(226.83deg, ${colors.cerulean_blue} 25.8%, #1487c8 123.58%);
  border-radius: 24px;
  max-width: ${sizes.XL};

  @media (max-width: ${sizes.M}) {
    height: 471px;
    border-radius: 0;
  }
`;

export const BannerImageContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  max-width: ${sizes.XL};
`;

export const BannerImage = styled(Image)`
  border-radius: 20px;
  max-width: ${sizes.XL};
  @media (max-width: ${sizes.M}) {
    object-fit: cover;
    object-position: left;
  }
`;

export const PromoImageContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 91px;
  width: 431px;
  max-width: ${sizes.XL};
  // height: 100%;
  @media (max-width: ${sizes.L}) {
    right: 20px;
  }
  @media (max-width: ${sizes.M}) {
    max-width: 100%;
    bottom: -40px;
    /* transform: translateX(-50%); */
    width: 90%;
    height: 278px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const PromoImage = styled(Image)`
  max-width: ${sizes.XL};
  @media (max-width: ${sizes.M}) {
    object-fit: contain;
  }
`;

export const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  position: absolute;
  top: 0;
  padding-left: 110px;
  max-width: ${sizes.XL};
  > div {
    display: flex;
    justify-content: space-around !important;
    > img {
      margin-right: 15px !important;
      cursor: pointer;
    }
  }
  @media (max-width: ${sizes.XL}) {
    padding-left: 40px;
  }
  @media (max-width: ${sizes.M}) {
    margin-top: 48px;
    width: 100%;
    padding-left: 0;
    justify-content: flex-start;
    align-items: center;
    > div {
      > img {
        margin-right: 0 !important;
      }
    }
  }
`;

export const BannerTitle = styled.div`
  font-family: BasierCircle;
  font-weight: 600;
  font-size: 36px;
  line-height: 50px;
  margin-bottom: 4px;
  color: #fff;
  max-width: ${sizes.XL};
`;

export const BannerDescription = styled.div`
  font-family: BasierCircle;
  font-size: 17px;
  line-height: 22px;
  margin-bottom: 33px;
  color: #fff;
  max-width: ${sizes.XL};
`;
